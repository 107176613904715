<template lang="html">
  <img src="@/assets/logo.png" alt="SThree" width="250" />
  <h1 class="title">
    Welke voorwaarden vind jij het belangrijkst bij een nieuwe baan?
  </h1>
  <div class="d-grid gap-2">
    <button
      v-for="answer in answers"
      v-bind:key="answer"
      type="button"
      class="btn btn-lg text-light bg-field"
      :class="[answerChecked(answer) ? 'btn-primary' : 'btn-secondary']"
      @click="addRemoveAnswer(answer)"
    >
      <i class="bi bi-square" v-if="!answerChecked(answer)"></i>
      <i class="bi bi-check-square" v-if="answerChecked(answer)"></i>
      {{ answer }}
    </button>
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      @click="setQuestionTwo()"
    >
      <span v-if="checkedAnswers.length < 1">Geen</span>
      <span v-if="checkedAnswers.length > 0">Volgende</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionOneScreen",
  data: () => ({
    answers: [
      "Basis-salaris",
      "Vakantiedagen",
      "5 vrijwilligersdagen",
      "Commissie",
      "Hybride werken",
      "Anders",
    ],
    checkedAnswers: [],
  }),
  computed: {},
  methods: {
    addRemoveAnswer(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        this.checkedAnswers.splice(this.checkedAnswers.indexOf(answer), 1);
      } else {
        this.checkedAnswers.push(answer);
      }
    },
    answerChecked(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        return true;
      }
      return false;
    },
    setQuestionTwo() {
      this.$store.commit("setQuestionTwo", this.checkedAnswers);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #000;
}
.bg-field {
  color: #fdf7ea !important;
}
</style>
