<template lang="html">
  <img src="@/assets/logo.png" alt="SThree" width="250" />
  <h1>Bedankt!</h1>
  <!-- <h2>Scan the QR code below on the claw machine for a free game.</h2> -->
  <h2>Scan de QR code op de grijpmachine en start het spel.</h2>
  <!-- <h2>Good luck!</h2> -->
  <h3>Succes!</h3>
  <div class="center">
    <img
      :src="
        'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=' +
        // 'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
        getSavedDataId
        // + '&choe=UTF-8'
      "
      title="Link to Google.com"
    />
  </div>
</template>

<script>
export default {
  name: "QrCodeScreen",
  data: () => ({}),
  computed: {
    getSavedDataId: function () {
      let savedData = this.$store.getters.getSavedData;
      if (savedData !== null) {
        return savedData._id;
      }
      return false;
    },
  },
  methods: {
    setQuestionTwo(answer) {
      this.$store.commit("setQuestionTwo", answer);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #000;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
.center {
  text-align: center;
}
</style>
